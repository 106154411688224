import {
  useEffect, useRef, useState
} from 'react';
import { atomOneLight, CodeBlock } from 'react-code-blocks';

export const LANGUAGES = {
  javascript: 'javascript',
  json: 'json',
  jsx: 'jsx',
  php: 'php',
  python: 'python',
  ruby: 'ruby',
  sql: 'sql',
  text: 'text',
  tsx: 'tsx',
  typescript: 'typescript',
  xml: 'xml',
  yaml: 'yaml',
};

const CopyButton = ({ isOverflowed, text }) => {
  const [isClicked, setIsClicked] = useState(false);

  return (
    <button
      className={`${
        isClicked ? 'active' : ''
      } group hover:overflow-visible overflow-hidden absolute top-0 ${
        isOverflowed ? 'right-32' : 'right-16'
      } top-16 z-1000 p-4`}
      onClick={() => {
        setIsClicked(true);
        navigator.clipboard.writeText(text);
      }}
      onMouseEnter={() => {
        setIsClicked(false);
      }}
    >
      <div className="flex flex-col items-center justify-center w-24 h-24 rounded-2 bg-lightest-plum">
        <img
          className="w-20 h-20 group-hover:hidden"
          src="https://rippling.imgix.net/images/icon-copy-plum.svg"
        />
        <img
          className="w-20 h-20 hidden group-hover:block"
          src="https://rippling.imgix.net/images/icon-copy-filled-plum.svg"
        />
      </div>

      <div className="group-hover:opacity-100 opacity-0 transition-opacity duration-300 tooltip absolute top-0 -left-104 flex flex-row justify-end w-104">
        <div className="text-11 leading-12 rounded-1 p-10 bg-tertiary-gray2 text-tertiary-gray5 font-medium flex flex-col items-center">
          {isClicked ? 'Copied!' : 'Copy'}
        </div>

        <div className="border-t-5 border-t-transparent border-l-5 border-l-tertiary-gray2 border-b-5 border-b-transparent h-0 w-0 top-12" />
      </div>
    </button>
  );
};

export const CodeSnippet = (props) => {
  const {
    _language, _showLineNumbers, _startingLineNumber, innerContent,
  } =
    props.attributes;

  const [isOverflowed, setIsOverflowed] = useState(false);

  const text = innerContent || '';

  const codeRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      const element = codeRef?.current.childNodes[1].childNodes[0];

      if (!element) {
        return;
      }

      const resizeObserver = new ResizeObserver(() => {
        setIsOverflowed(element.scrollHeight > element.clientHeight);
      });

      resizeObserver.observe(element);

      setIsOverflowed(element.scrollHeight > element.clientHeight);

      return () => {
        resizeObserver.disconnect();
      };
    }, 500);
  }, []);

  return (
    <div ref={codeRef} className="CodeSnippet">
      <CopyButton isOverflowed={isOverflowed} text={text} />

      <CodeBlock
        language={_language}
        showLineNumbers={_showLineNumbers}
        startingLineNumber={Number.parseInt(_startingLineNumber)}
        text={text}
        theme={atomOneLight}
      />
    </div>
  );
};
