import React from 'react';

import Link from '../../templates/partials/LinkHelper';
import Image from '../basic/Image';

const CsInnerCta = (props) => {
  const { attributes } = props;
  const {
    buttonText,
    buttonUrl,
    headline,
    iconUrl,
    imageUrl,
    layoutType,
    subcopy,
  } = attributes;

  return (
    <Link
      className={`CsInnerCta bg-white p-12 rounded-12 my-48 sm:my-64 md:my-80 ${
        layoutType === 'narrow'
          ? 'max-w-300 hidden md:display-grid'
          : 'max-w-725 display-grid'
      } ${
        layoutType === 'two-column'
          ? 'display-grid sm:grid-cols-2 xsx:grid-rows-2'
          : 'grid-cols-1'
      }`}
      href={buttonUrl}
    >
      <div
        className={`flex flex-col pl-20 py-20 rounded-12 ${
          layoutType === 'two-column' ? 'pr-20 sm:pr-12' : 'pr-20'
        }`}
      >
        {iconUrl ? (
          <div className="max-w-152 flex flex-col mb-24">
            <Image
              attributes={{
                className: 'w-100%',
                mediaAlt: '',
                mediaURL: iconUrl,
                nonResponsive: true,
              }}
            />
          </div>
        )

          : null}

        <h3 className="text-plum-800 mb-8 max-w-550">{headline}</h3>

        <p className="is-style-p1 text-plum-800 opacity-70 mb-24 max-w-550">
          {subcopy}
        </p>

        <button className="bg-yellow is-style-p2 py-16 font-medium md:max-w-350 w-100% cursor-pointer rounded-4">
          {buttonText}
        </button>
      </div>

      {layoutType === 'two-column' ? (
        <div
          className={`flex flex-col flex flex-col justify-center items-center rounded-12 ${
            layoutType === 'two-column' ? 'sm:ml-36' : ''
          } `}
        >
          {imageUrl ? (
            <div className="max-w-300">
              <Image
                attributes={{
                  className: 'w-100%',
                  mediaAlt: '',
                  mediaURL: imageUrl,
                  nonResponsive: true,
                }}
              />
            </div>
          )
            : null}
        </div>
      )

        : null}
    </Link>
  );
};

export default CsInnerCta;
