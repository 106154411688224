import React from 'react';

import Link from '../../templates/partials/LinkHelper';
import Image from '../basic/Image';

const GlossaryDarkImageCta = ({ attributes }) => {
  let {
    buttonText, buttonUrl, headline,
  } = attributes;

  headline = headline || 'Schedule a demo with Rippling today';
  buttonText = buttonText || 'See Rippling';
  buttonUrl = buttonUrl || '/request-demo';

  return (
    <div className="wrapper max-w-100%  pb-24 md:pb-48 pt-24 md:pt-48 md:max-w-725  md:px-0 w-100%">
      <Link
        className= "block wrapper group bg-plum flex flex-col items-end justify-center mx-auto overflow-hidden rounded-4 text-center w-100% sm:flex-row sm:max-w-725 sm:text-left no-underline"
        href={buttonUrl}
      >
        <div className="wrapper w-100% sm:w-50%">
          <div className="wrapper pb-0 pt-32 px-24 sm:pl-48 sm:pr-24 sm:py-48">
            <p className="font-semibold leading-26 max-w-100% mb-8 text-22 text-center text-white sm:leading-30 sm:max-w-300 sm:text-24 sm:text-left">
              {headline}
            </p>
            <div className="wrapper flex items-center justify-center mt-24 relative sm:justify-start sm:mt-32">
              <div
                className="btn btn bg-yellow leading-22 min-w-150 px-30 py-10 rounded-2 text-15"
              >
                <span>{buttonText}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="wrapper sm:justify-left flex flex-wrap items-center justify-center pb-22 pt-36 px-24 text-center w-100% sm:pb-0 sm:pt-0 sm:px-0 sm:w-50%">
          <div className="block imagex  pr-0 no-underline">
            <Image
              alt=""
              attributes={{
                mediaURL: 'https://rippling.imgix.net/images/blog-inline-generic-cta-image.svg?fm=svg',
                nonResponsive: true,
              }}
            />
          </div>
        </div>
      </Link>
    </div>
  );
};

export default GlossaryDarkImageCta;
