import { useEffect, useRef } from 'react';

type AcceptableKey = 'Escape';

interface UseKeyboardArgs {
  enabled: boolean;
  key: AcceptableKey;
  onPress: () => void;
}

export default function useKeyboard({
  enabled,
  key,
  onPress,
}: UseKeyboardArgs) {
  const ref = useRef(onPress);

  // Just in case if `onPress` is not stable
  useEffect(() => {
    ref.current = onPress;
  }, [onPress]);

  useEffect(() => {
    if (!enabled) {
      return;
    }

    const onKeyUp = (event) => {
      if (event.key === key) {
        ref.current();
      }
    };

    document.addEventListener('keyup', onKeyUp, false);

    return () => {
      document.removeEventListener('keyup', onKeyUp, false);
    };
  }, [enabled, key]);
}
