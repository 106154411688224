import React from 'react';

const constructList = (data) => {
  const children = data.children || data.props.innerBlocks?.[0]?.innerBlocks;

  if (!children) {
    return '';
  }

  return children.reduce((result, child) => {
    const content =
      child.props?.attributes?.content ||
      child.props?.attributes?.innerContent ||
      child.attributes?.content ||
      child.attributes?.innerContent;
    let innerResult = `${result}<li>${content}</li>`;

    if (child.props?.innerBlocks.length) {
      const el = child.props.attributes.ordered ? 'ol' : 'ul';

      innerResult += `<${el}>${constructList(child, true)}</${el}>`;
    }

    return innerResult;
  }, '');
};

const List = (data) => {
  const { attributes } = data;
  let {
    className, innerContent, ordered,
  } = attributes;
  const El = ordered ? 'ol' : 'ul';
  innerContent = data.children?.length ? constructList(data) : innerContent;

  return (
    <El
      dangerouslySetInnerHTML={{ __html: innerContent }}
      className={`${className}`}
    />
  );
};

export default List;
