import React, { useLayoutEffect } from 'react';

import classnames from 'classnames';
import { gsap, ScrollTrigger } from 'gsap/dist/all';
import { useRouter } from 'next/router';
import Cookies from 'universal-cookie';

const Gsap = (props) => {
  const router = useRouter();
  const { attributes, children } = props;
  let {
    className, data, onMount,
  } = attributes;

  gsap.registerPlugin(ScrollTrigger);

  const fullClassName = `${className} ${classnames()}`;
  className = `${fullClassName}`;

  useLayoutEffect(() => {
    if (onMount) {
      const cookies = new Cookies();
      const context = gsap.context(() => {
        try {
          window?.gsap ? null : window.gsap = gsap;
          window?.ScrollTrigger
            ? null
            : window.ScrollTrigger = ScrollTrigger;
          new Function(onMount)({
            cookies,
            router,
          });
        } catch (error) {
          console.error(error);
        }
      });

      return () => context.revert();
    }
  }, [onMount]);

  return (
    <div className={className} {...data}>
      {children}
    </div>
  );
};

export default Gsap;
