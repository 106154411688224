import React from 'react';

import { useRouter } from 'next/router';
import Cookies from 'universal-cookie';

const IfElse = (data) => {
  const router = useRouter();
  const cookies = new Cookies();
  const { attributes, children } = data;
  const { iff, logic } = attributes;
  let chosenChild;

  if (logic) {
    chosenChild = new Function('router', 'cookies', logic)(router, cookies)
      ? children[0].props.children
      : children[1]?.props.children;
  } else {
    chosenChild =
      iff && iff !== 'false'
        ? children[0].props.children
        : children[1]?.props.children;
  }

  return <>{chosenChild}</>;
};

export default IfElse;
