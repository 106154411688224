import React from 'react';
import { Waypoint } from 'react-waypoint';

import loadable from '@loadable/component';

let Lottie;

class Animation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      entered: false,
      isPaused: true,
      isStopped: true,
    };

    this.onEnter = this.onEnter.bind(this);
    this.onLeave = this.onLeave.bind(this);
    this.onStart = this.onStart.bind(this);
    this.onRefChange = this.onRefChange.bind(this);
  }

  componentDidMount() {
    Lottie = loadable(() => import('react-lottie-segments'));
  }

  onRefChange(node) {
    new Function('values', this.props.attributes.onMount)({ ref: node });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isActive !== this.props.isActive) {
      this.setState({
        isPaused: true,
        isStopped: true,
      });

      if (this.props.isActive) {
        this.setState({
          isPaused: false,
          isStopped: false,
        });
      }
    }
  }

  onEnter() {
    // On first enter, have load start animation, unpause on subsequent enters
    if (this.state.entered) {
      this.setState({ isPaused: false });
    } else {
      this.setState({ entered: true });
    }
  }

  onLeave() {
    this.setState({ isPaused: true });
  }

  onStart() {
    const { delay, dontPlayOnEnter } = this.props.attributes;
    if (dontPlayOnEnter) {
      return;
    }

    // eslint-disable-next-line unicorn/no-this-assignment
    const that = this;
    const timeToVideo = delay && !this.state.showVideo ? 3000 : 0;

    if ((!this.props.isToggled || this.props.isActive) && !window.isVRTest) {
      setTimeout(() => {
        that.setState({ showVideo: true }, () => {
          this.setState({
            isPaused: false,
            isStopped: false,
          });
        });
      }, timeToVideo);
    }
  }

  render() {
    const { attributes, onComplete } = this.props;
    const {
      bottomOffset,
      className,
      delayOnEnd,
      opts,
      renderImmediately,
      url,
    } = attributes;
    const {
      loop, rendererSettings, segments,
    } = opts || {};

    const options = {
      autoplay: false,
      loop: loop ? JSON.parse(loop) : false,
      path: url,
      renderer: 'svg',
      rendererSettings: rendererSettings ? JSON.parse(rendererSettings) : null,
    };

    if (segments) {
      var sequence = {
        forceFlag: true,
        segments: JSON.parse(segments),
      };
    }

    const eventListeners = [
      {
        callback: (e) => this.onStart(e),
        eventName: 'DOMLoaded',
      },
    ];

    // Stop video on last frame if not looped
    // if (!loop) {
    //   eventListeners.push({
    //     eventName: 'enterFrame',
    //     callback: (animation) => {
    //       if (this.el && animation.currentTime > (this.el.totalFrames - 1)) {
    //         this.el.pause();
    //      }
    //     }
    //   })
    // }

    if (onComplete) {
      eventListeners.push({
        callback: () => {
          onComplete(this.props.activeIndex);
        },
        eventName: 'complete',
      });
    }

    if (delayOnEnd) {
      eventListeners.push({
        callback: () => {
          setTimeout(() => {
            this.el?.goToAndPlay(0, true);
          }, Number.parseInt(delayOnEnd));
        },
        eventName: 'complete',
      });
    }

    const animationJSX = (
      <div className={`${className}`} data-lottie>
        {Lottie ? (
          <Lottie
            ref={this.onRefChange}
            eventListeners={eventListeners}
            isPaused={this.state.isPaused}
            isStopped={this.state.isStopped}
            options={options}
            playSegments={sequence || undefined}
          />
        )
          : undefined}
      </div>
    );

    return renderImmediately ?
      animationJSX
      : (
        <Waypoint
          bottomOffset={bottomOffset || '-20%'}
          onEnter={this.onEnter}
          onLeave={this.onLeave}
        >
          {this.state.entered ? animationJSX : undefined}
        </Waypoint>
      );
  }
}

export default Animation;
