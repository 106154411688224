import React from 'react';

const GlossaryDisclaimer = (props) => {
  const { attributes } = props;
  const { copy } = attributes;

  return (
    <div className="mb-24">
      <div className="grid sm:max-w-725 w-100%">
        <div className="row">
          <div className="column flex flex-col items-start w-100%">
            <p className="is-style-p2 font-italic">
              <i
                dangerouslySetInnerHTML={{ __html: copy }}
              />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GlossaryDisclaimer;
