import React from 'react';

import { Field } from 'formik';

class Radios extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: null,
      children: null,
    };
  }

  render() {
    const {
      attributes,
      handleChange,
      isActive,
      renderedAsFormButtons,
      values,
    } = this.props;
    const {
      className,
      innerClass,
      labelClass,
      name,
      opts,
      radioClass,
      selectMultiple,
    } = attributes;

    const optKeys = opts && Object.keys(opts);
    values[name] = values[name] || [];

    const result = (optKeys || []).map((option, i) => {
      var label = option;
      var value = opts[option];
      var checked;

      if (selectMultiple) {
        checked = values[name].includes(value) ? true : false;
      } else {
        checked = values[name] === value + '' ? true : false;
      }

      const id =
        typeof Number.parseInt(value) === 'number' ? `id${value}${Date.now()}` : value;

      // the below was added to support radios as form buttons, so that the first always appears to be the "default"/preferred option
      const nothingCheckedYet = values[name].length === 0;
      const isFirstOption = i === 0;
      const shouldShowAsDefaultButton =
        renderedAsFormButtons && isFirstOption && nothingCheckedYet;
      const showAsChecked = shouldShowAsDefaultButton || checked;

      return (
        <div key={`${id}-${i}`} className={`${innerClass}${showAsChecked ? ' checked' : ''}`}>
          <Field
            checked={checked}
            className={radioClass}
            id={id}
            name={name}
            onChange={(e) => {
              // Note: Since radio buttons remain focused after selection,
              // we need to manually blur them to immediately update .touched
              // (including the first click)
              e.currentTarget.blur();
              handleChange(e);
            }}
            tabIndex={isActive ? undefined : -1}
            type={selectMultiple ? 'checkbox' : 'radio'}
            value={value}
          />

          <label
            key={i}
            className={`${labelClass} radio mb-12`}
            htmlFor={id}
          >
            <span dangerouslySetInnerHTML={{ __html: label }} />
          </label>
        </div>
      );
    });

    return <div className={`${className}`}>{result}</div>;
  }
}

export default Radios;
