import React from 'react';
import { Waypoint } from 'react-waypoint';

class Video extends React.Component {
  constructor(props) {
    super(props);

    this.state = { showVideo: false };

    this._handleEnter = this._handleEnter.bind(this);
    this._handleLeave = this._handleLeave.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isActive !== this.props.isActive) {
      this.video.current.pause();
      this.video.current.currentTime = 0;
    }

    if (this.props.isActive) {
      this.video.current.play();
    }
  }

  _handleEnter() {
    const { delay } = this.props.attributes;

    // eslint-disable-next-line unicorn/no-this-assignment
    const that = this;
    const timeToVideo = delay && !this.state.showVideo ? 3000 : 0;

    if (!this.props.isToggled || this.props.isActive) {
      setTimeout(() => {
        that.setState({ showVideo: true }, () => {
          that.video.current.play();
        });
      }, timeToVideo);
    }
  }

  _handleLeave() {
    if (this.video.current) {
      this.video.current.pause();
    }
  }

  render() {
    const {
      attributes, onEnded, onSeeked, onTimeUpdate,
    } =
      this.props;
    const {
      autoPlayOff,
      className,
      data,
      delay,
      loop,
      posterURL,
      renderImmediately,
      url,
    } = attributes;

    const preload = autoPlayOff ? 'none' : null;

    this.video = this.props.theRef || React.createRef();

    const videoJSX = (
      <video
        {...data}
        ref={this.video}
        className={`video${className ? ` ${className}` : ''}`}
        loop={loop}
        muted
        onEnded={onEnded}
        onLoadedMetadata={this.props.onLoadedMetadata}
        onSeeked={onSeeked}
        onTimeUpdate={onTimeUpdate}
        playsInline
        poster={posterURL}
        preload={preload}
      >
        <source src={url} type="video/mp4" />
      </video>
    );

    return renderImmediately ?
      videoJSX
      : (
        <Waypoint onEnter={this._handleEnter} onLeave={this._handleLeave}>
          {this.state.showVideo || !delay ? videoJSX : undefined}
        </Waypoint>
      );
  }
}

export default Video;
