import React from 'react';

const Submit = (props) => {
  const { attributes } = props;
  const {
    className, data, onClick, value,
  } = attributes;

  return (
    <div className={className}>
      <input
        className="button -primary -large -full"
        onClick={onClick}
        type="submit"
        value={value}
        {...data}
      />
    </div>
  );
};

export default Submit;
