import React from 'react';

import classnames from 'classnames';

const Fields = (data) => {
  const { attributes, children } = data;
  const { className } = attributes;

  return (
    <div className={`fields ${classnames({ [className]: className })}`}>
      {children}
    </div>
  );
};

export default Fields;
