import React, {
  useEffect, useRef, useState
} from 'react';

import amplitude from 'amplitude-js';
import classnames from 'classnames';
import { ErrorMessage, Field } from 'formik';

import { shuffle } from '../../helpers';

const Select = (data) => {
  const {
    attributes,
    errors,
    formRef,
    isActive,
    setValues,
    touched,
    values,
  } = data;
  const {
    className,
    dependency,
    innerClassName,
    isDependentField,
    isRequired,
    label,
    multiSelect,
    name,
    onMount,
    onSelectChange,
    onSelectChangeDeprecated,
    opts,
    randomizeOptions,
    showError,
  } = attributes;

  let [optKeys, setOptKeys] = useState(opts && Object.keys(opts));

  const inputEl = useRef(null);

  const onChange = (e, altValue) => {
    if (onSelectChange) {
      typeof onSelectChange === 'string'
        ? new Function('values', onSelectChange)({
          e,
          setValues,
          values,
        })
        : onSelectChange(e);
    }

    if (onSelectChangeDeprecated) {
      onSelectChangeDeprecated(e);
    }

    if (values) {
      setValues(Object.assign(values, { [name]: altValue || e.target.value }));
    }
  };

  const revealDependentField = (e, isOnLoad) => {
    if (dependency) {
      const operators = {
        '<': (a, b) => a < b,
        '<=': (a, b) => a <= b,
        '=': (a, b) => a === b,
        '>': (a, b) => a > b,
        '>=': (a, b) => a >= b,
      };
      var [
        _eslintIgnore_depName,
        operator,
        depValue,
      ] = dependency.split(',');

      var fieldValue = isOnLoad ? values[name] || '' : e.target.value;

      fieldValue = Number.parseInt(fieldValue) || fieldValue;
      depValue = Number.parseInt(depValue) || depValue;
      let isRevealed;

      if (typeof depValue === 'string' && depValue.includes('|')) {
        const depValues = depValue.split('|');

        isRevealed = depValues.find((value) =>
          operators[operator](fieldValue, value));
      } else {
        isRevealed = operators[operator](fieldValue, depValue);
      }

      const method = isRevealed ? 'add' : 'remove';
      const hiddenInput = inputEl.current?.nextSibling;

      if (hiddenInput) {
        const hiddenField = hiddenInput.closest('.-is-dependent-field');

        if (hiddenField) {
          hiddenField.classList[method]('-is-revealed');
        }
      }
    }
  };

  useEffect(() => {
    revealDependentField(null, true);
    optKeys =
      optKeys && randomizeOptions
        ? optKeys.slice(0, 1).concat(shuffle(optKeys.slice(1)))
        : optKeys;
    setOptKeys(optKeys);

    if (onMount) {
      new Function(
        'opts',
        'setOptKeys',
        'setValues',
        'values',
        'name',
        onMount
      )(opts, setOptKeys, setValues, values, name);
    }
  }, []);

  const Wrapper = label ? 'label' : 'div';

  const El = values ? Field : 'select';
  const multiSelectHiddenInputRef = useRef(null);
  const multiSelectInputRefs = useRef(optKeys.map(() => ({ checked: false })));
  const [multiSelectHiddenInputValue, setMultiSelectHiddenInputValue] = useState('');

  const MultiSelectItem = ({
    formRef,
    index,
    keyName,
    multiSelectInputRefs,
    name,
    onChange,
    opts,
    revealDependentField,
    setMultiSelectHiddenInputValue,
  }) => {
    const [isChecked, setIsChecked] = useState(() => multiSelectInputRefs.current[index]?.checked || false);

    useEffect(() => {
      // Keep multiSelectInputRefs in sync with isChecked whenever it changes
      const inputRef = multiSelectInputRefs.current[index];
      if (inputRef) {
        inputRef.checked = isChecked;
      }
    }, [
      isChecked,
      index,
      multiSelectInputRefs,
    ]);

    const handleInputChange = (e) => {
      const newCheckedState = e.target.checked;
      setIsChecked(newCheckedState);

      formRef?.current?.querySelector(`[name=${name}]`)?.classList.add('-touched');
      revealDependentField(e);

      updateSelectedValues();
    };

    const handleLabelClick = () => {
      const inputRef = multiSelectInputRefs.current[index];
      if (inputRef) {
        const newCheckedState = !inputRef.checked;
        inputRef.checked = newCheckedState;
        setIsChecked(newCheckedState);
        updateSelectedValues();
      }
    };

    const updateSelectedValues = () => {
      const selectedValues = multiSelectInputRefs.current
        .filter((el, i) => i > 0 && el?.checked)
        .map((el) => el.value);

      const resultStr = selectedValues.join(', ');
      setMultiSelectHiddenInputValue(resultStr);
      onChange(null, resultStr);
    };

    return (
      <div className="group">
        <input
          ref={(el) => multiSelectInputRefs.current[index] = el}
          checked={isChecked}
          name={`${name}_${index}`}
          onChange={handleInputChange}
          type="checkbox"
          value={keyName}
        />
        <label onClick={handleLabelClick}>
          <p
            className={`is-style-p2 ease-in-out transition-all ${
              isChecked ? 'text-white font-medium' : 'group-hover:text-white text-plum-200'
            }`}
          >
            {opts[keyName]}
          </p>
        </label>
      </div>
    );
  };

  return (
    <Wrapper
      ref={inputEl}
      className={classnames({
        '-is-dependent-field': isDependentField,
        [className]: className,
        'field-2': !!label,
        'multi-select': multiSelect,
        select: !multiSelect,
      })}
      onChange={onChange}
    >
      {label && <span className="field_label">{label}</span>}
      {multiSelect ? (
        <>
          <input
            ref={multiSelectHiddenInputRef}
            className={classnames({ [innerClassName]: innerClassName })}
            name={name}
            required={isRequired}
            tabIndex={!isActive || isDependentField ? -1 : undefined}
            type="hidden"
            value={multiSelectHiddenInputValue}
          />
          <div className="multi-select-wrapper flex flex-col pt-4 pb-8 ">
            <div className="flex flex-row justify-between">
              <p className="is-style-p2 text-plum-200">{opts['']}</p>
            </div>
          </div>

          <div
            className={`flex options flex-col px-16 border-1 border-[#825568] bg-[#663D4F] rounded-4 py-16 gap-8 ${
              errors?.[name] && touched?.[name]
                ? '-error border-[#e74c3c] border-2'
                : ''
            } ${touched?.[name] ? '-touched' : ''}}`}
          >
            {optKeys &&
              optKeys.map((key, i) => {
                // Only render if index is greater than 0
                if (i <= 0) {
                  return null;
                }

                return (
                  <MultiSelectItem
                    key={i}
                    formRef={formRef}
                    index={i}
                    keyName={key}
                    multiSelectInputRefs={multiSelectInputRefs}
                    name={name}
                    onChange={onChange}
                    opts={opts}
                    revealDependentField={revealDependentField}
                    setMultiSelectHiddenInputValue={setMultiSelectHiddenInputValue}
                  />
                );
              })}
          </div>
        </>
      ) : (
        <El
          as="select"
          className={classnames({
            '-error': errors?.[name] && touched?.[name],
            '-touched': touched?.[name],
            [innerClassName]: innerClassName,
          })}
          name={name}
          onChange={(e) => {
            formRef?.current
              ?.querySelector(`[name=${name}]`)
              .classList.add('-touched');
            revealDependentField(e);
          }}
          onClick={() => {
            if (name === 'schedule_demo_timezone') {
              amplitude.getInstance().logEvent('Click - Timezone Selector ');
            }
          }}
          required={isRequired}
          tabIndex={!isActive || isDependentField ? -1 : undefined}
        >
          {optKeys &&
            optKeys.map((key) => (
              <option key={key} value={key}>
                {opts[key]}
              </option>
            ))}
        </El>
      )}
      {showError && (
        <ErrorMessage
          className="form_errorMessage"
          component="div"
          name={name}
        />
      )}
    </Wrapper>
  );
};

export default Select;
