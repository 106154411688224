import React from 'react';

import amplitude from 'amplitude-js';
import classnames from 'classnames';
import { withRouter } from 'next/router';
import Cookies from 'universal-cookie';

import { getXHoursFromNow } from '../../../helpers/date-helpers';
import { encodeHTML, parseChildren } from '../../helpers';

class Steps extends React.Component {
  constructor(props) {
    super(props);
    this.state = { data: null };
    this.activeIndex = 0;
    this.handleClick = this.handleClick.bind(this);
    this.onMount = this.onMount.bind(this);
    this.multistepCompletions = {};
    this.loggedEmail = false;
    this.userSteps = this.props.steps;
    this.userStepsLength = this.userSteps.length;
  }

  componentDidMount() {
    const data = parseChildren(this.props, this.onMount);

    this.header = document.querySelector('.header');
    this.section = document.querySelector('.multistepForm_section');

    this.setState({ data });
  }

  componentDidUpdate(prevProps) {
    if (
      this.pathname !== window.location.pathname ||
      prevProps.errors !== this.props.errors ||
      prevProps.touched !== this.props.touched ||
      prevProps.complete !== this.props.complete
    ) {
      this.pathname = window.location.pathname;

      if (this.props.complete) {
        this.activeIndex = this.userStepsLength;

        if (this.section) {
          const sectionClassList = this.section.classList;

          sectionClassList.remove('-stage-3');
          this.section.classList.add('-completed');

          if (window.innerWidth < 768) {
            this.section.scrollIntoView();
          }

          if (this.section.classList.contains('multistepForm_section')) {
            const isNewForm =
              this.section.classList.contains('bg-home-2') ||
              this.section.classList.contains('bg-home-3') ||
              this.section.classList.contains('bg-home-4');
            const stepId = `${this.activeIndex}`;
            const event = isNewForm
              ? `multistep${stepId}-completion`
              : `multistepOriginal${stepId}-completion`;

            window.dataLayer.push({ event });

            amplitude.getInstance().logEvent('Multistep Step Completion', { 'Step Completed': `Step ${stepId} Completed` });
          }
        }
      }

      const data = parseChildren(this.props, this.onMount);
      this.setState({ data });
    }
  }

  handleClick(i) {
    const step = this.userSteps[this.activeIndex];
    let errors = {};

    if (this.activeIndex + i < this.userStepsLength) {
      if (i > 0) {
        errors = Object.keys(this.props.validate(this.props.values, step));
      }

      if (errors.length === 0) {
        const cookies = new Cookies();
        const variationId = cookies.get('optimizelyVariationID');

        if (variationId === '20523971004') {
          const hostname = window.location.hostname;
          cookies.set('user_email', this.props.values.user_email, {
            domain: hostname,
            expires: getXHoursFromNow(1),
            path: '/',
          });

          this.props.router.push('/request-demo');
        } else {
          const previousIndex = this.activeIndex;
          this.activeIndex = this.activeIndex + i;

          if (this.section) {
            const isNewForm =
              this.section.classList.contains('bg-home-2') ||
              this.section.classList.contains('bg-home-3') ||
              this.section.classList.contains('bg-home-4');

            if (window.innerWidth < 768) {
              this.section.scrollIntoView();
            }

            if (
              this.activeIndex > 0 &&
              this.section.classList.contains('multistepForm_section')
            ) {
              const stepId = `${this.activeIndex}`;

              if (!this.multistepCompletions[stepId]) {
                const event = isNewForm
                  ? `multistep${stepId}-completion`
                  : `multistepOriginal${stepId}-completion`;

                window.dataLayer.push({ event });

                amplitude.getInstance().logEvent('Multistep Step Completion', { 'Step Completed': `Step ${stepId} Completed` });

                this.multistepCompletions[stepId] = true;
              }
            }
          }

          const dependency = this.props.dependencies[this.activeIndex];

          if (dependency) {
            const [depKey, depValue] = dependency.split(':');
            if (this.props.values[depKey] !== depValue) {
              this.activeIndex = this.activeIndex + i;
            }
          }

          if (this.section) {
            this.section.classList.remove(`-stage-${previousIndex}`);
            const method =
              this.activeIndex > 0 && this.activeIndex < this.userStepsLength
                ? 'add'
                : 'remove';

            this.section.classList[method]('-started');
            if (
              this.section.classList.contains('bg-home-2') ||
              this.section.classList.contains('bg-home-3') ||
              this.section.classList.contains('bg-home-4')
            ) {
              this.header.classList[method]('-started');
            }

            if (this.activeIndex > 0) {
              this.section.classList.add(`-stage-${this.activeIndex}`);
            }
          }

          const data = parseChildren(this.state.data, this.onMount);
          this.setState({ data });
        }
      }
    }
  }

  onMount(data, children, index) {
    const {
      errors,
      formRef,
      onSelectChange,
      setValues,
      touched,
      values,
    } = this.props;
    const { attributes } = data.props;
    const { className } = attributes;

    if (data.props.name === 'radios') {
      attributes.data.onClick = () => this.handleClick(1);

      return {
        el: data,
        props: {
          attributes,
          isActive: this.stepIndex === this.activeIndex,
          setValues,
          values,
        },
      };
    }

    if (data.props.name === 'field' || data.props.name === 'date-picker') {
      return {
        el: data,
        props: {
          errors,
          formRef,
          isActive: this.stepIndex === this.activeIndex,
          setValues,
          touched,
          values,
        },
      };
    }

    if (data.props.name === 'select') {
      return {
        el: data,
        props: {
          errors,
          formRef,
          isActive: this.stepIndex === this.activeIndex,
          onSelectChange,
          setValues,
          touched,
          values,
        },
      };
    }

    if (data.props.name === 'button') {
      return {
        el: data,
        props: {
          errors,
          touched,
          values,
        },
      };
    }

    if (/multistepForm_step/.test(className)) {
      if (this.userSteps?.length) {
        this.stepIndex = index;
        attributes.data['data-active'] = this.activeIndex === index || null;

        const complete = this.userSteps[index].some((value) =>
          Object.keys(this.props.errors).includes(value))
          ? null
          : true;
        attributes.data['data-complete'] = complete;
      }

      return {
        el: data,
        props: {
          attributes,
          children,
        },
      };
    }

    if (/multistepForm_next/.test(className)) {
      attributes.data.onClick = () => this.handleClick(1);

      return {
        el: data,
        props: { attributes },
      };
    }

    if (/multistepForm_prev/.test(className)) {
      attributes.data.onClick = () => this.handleClick(-1);

      return {
        el: data,
        props: { attributes },
      };
    }

    if (/multistepForm_thankYouMessage/.test(className)) {
      this.userStepsLength = this.userSteps.length - 1;

      if (this.props.values && this.props.values.user_email) {
        attributes.innerInnerContent = encodeHTML(`${this.props.values.user_email}`);

        return {
          el: data,
          props: { attributes },
        };
      }
    }

    return {
      el: data,
      props: { children },
    };
  }

  render() {
    const { attributes } = this.props;
    const { className } = attributes;

    return (
      <div
        className={`multistepForm_steps ${classnames({ [className]: className })}`}
      >
        {this.state.data && this.state.data.children}
      </div>
    );
  }
}

export default withRouter(Steps);
