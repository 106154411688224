import React, {
  ReactNode, useEffect, useRef, useState
} from 'react';

import clsx from 'clsx';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  entityValue: ReactNode;
  eorValue: ReactNode;
  title: string;
  canSeeChildren?: boolean;

  // The "children" props also used as a way to indicate whether or not we add a chevron
  children?: ReactNode;
  entityClassName?: string;
  eorClassName?: string;
  noBorder?: boolean;
  onClick?: () => void;
  titleClassName?: string;
}

const TableDataRow = ({
  canSeeChildren = false,
  children,
  className = 'bg-white',
  entityClassName,
  entityValue,
  eorClassName,
  eorValue,
  noBorder,
  onClick,
  title,
  titleClassName,
}: Props) => {
  const isClickedRef = useRef(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const handleClick = () => {
    isClickedRef.current = true;

    if (canSeeChildren) {
      setIsExpanded(!isExpanded);
    }

    onClick?.();
  };

  // After completing form, expand the row
  useEffect(() => {
    if (!isClickedRef.current || !canSeeChildren || !children) {
      return;
    }

    setIsExpanded(true);
  }, [canSeeChildren, children]);

  return (
    <div
      className={clsx(
        !noBorder && 'border-solid border-l-1 border-r-1 border-tertiary-gray2',
        'display-grid grid-cols-[1fr_1fr_1fr]',
        'xsx:grid-rows-[auto_1fr] xsx:grid-cols-[1fr_1fr]',
        className
      )}
    >
      <div
        className={clsx(
          'relative flex items-center justify-between px-32 py-24 text-plum-800',
          'border-solid border-b-1 border-tertiary-gray2',
          'xsx:row-start-1 xsx:col-span-2 xsx:justify-center xsx:border-b-1 border-tertiary-gray2 xsx:px-16',
          children && 'xsx:bg-white',
          titleClassName
        )}
      >
        <span>{title}</span>
        {children && (
          <img
            alt={isExpanded ? 'Hide' : 'Show'}
            className={clsx(
              'cursor-pointer transition-all ease-in-out duration-300 xsx:absolute xsx:right-32',
              isExpanded && 'rotate-180'
            )}
            onClick={handleClick}
            src="https://rippling.imgix.net/images/chevron-down.svg"
          />
        )}
      </div>
      <div
        className={clsx(
          'flex justify-center items-center px-32 py-24 text-plum-800',
          'border-solid border-l-1 border-r-1 border-b-1 border-tertiary-gray2',
          'xsx:row-start-2 xsx:col-start-1 xsx:border-l-0 xsx:px-16',
          eorClassName
        )}
      >
        <span className="break-all">{eorValue}</span>
      </div>
      <div
        className={clsx(
          'flex justify-center items-center px-32 py-24 text-plum-800',
          'border-solid border-b-1 border-tertiary-gray2',
          'xsx:row-start-2 xsx:col-start-2 xsx:px-16',
          entityClassName
        )}
      >
        <span className="break-all">{entityValue}</span>
      </div>
      {children && (
        <div
          className={clsx(
            'display-grid col-span-3 transition-all ease-in-out duration-300',
            isExpanded ? 'grid-rows-[1fr]' : 'grid-rows-[0fr]'
          )}
        >
          <div className="overflow-hidden">{children}</div>
        </div>
      )}
    </div>
  );
};

export default TableDataRow;
