import React from 'react';

import classnames from 'classnames';

import LinkHelper from '../../templates/partials/LinkHelper';

const Wrapper = (props) => {
  const { attributes, children } = props;
  let {
    alias,
    className,
    data,
    inlineHTML,
    link,
    openInNewTab,
    simulateClick,
  } = attributes;

  const baseClassName = [
    'Row',
    'Grid',
    'Aligner',
    'Column',
    'ImageX',
    'VideoX',
    'IconX',
  ].includes(alias)
    ? alias.toLowerCase()
    : 'wrapper';

  const fullClassName = `${className} ${classnames({ 'no-underline': link })}`;
  className = `${baseClassName} ${fullClassName}`;
  const anchorProps = {
    className: `block ${baseClassName} ${fullClassName}`,
    rel: openInNewTab && 'noopener noreferrer',
    target: openInNewTab && '_blank',
  };

  const getOuterElement = () => {
    return link ? (
      <LinkHelper
        anchorProps={anchorProps}
        data={data}
        href={link}
        simulateClick={simulateClick}
      >
        {inlineHTML ?
          <div dangerouslySetInnerHTML={{ __html: inlineHTML }} />
          : undefined}
        {children}
      </LinkHelper>
    )

      : (
        <div className={className} {...data}>
          {inlineHTML ?
            <div dangerouslySetInnerHTML={{ __html: inlineHTML }} />
            : undefined}
          {children}
        </div>
      );
  };

  return getOuterElement();
};

export default Wrapper;
