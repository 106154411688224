import React from 'react';

import Image from '../basic/Image';

const getIcon = (icon) => {
  switch (icon) {
    case 'pain': {
      return 'https://rippling2.imgix.net/icon-cs-error.svg';
    }
    case 'solution': {
      return 'https://rippling2.imgix.net/icon-cs-success.svg';
    }
    case 'result': {
      return 'https://rippling2.imgix.net/icon-cs-add.svg';
    }
    case 'impact': {
      return 'https://rippling2.imgix.net/icon-cs-bar-chart-increase.svg';
    }
    case 'challenge': {
      return 'https://rippling2.imgix.net/Lightning_Bolt.svg';
    }
    default: {
      return 'https://rippling2.imgix.net/icon-cs-error.svg';
    }
  }
};

const slugify = (name) =>
  name &&
    name
      .toLowerCase()
      .replaceAll(/\(|\)|\s\/|,|’|'/g, '')
      .replaceAll('/', ' ')
      .replaceAll(/\s/g, '-')
      .replaceAll('&', 'and') ||
  null;

const getPostBlocksFiltered = (blocks, sideArr) => {
  // runs shallow check looking for blocks with special identifiers (we can use data.ids on the wrappers)
  const blocksObj = {
    body: [],
    side: [],
  };
  for (const block of blocks) {
    const layout = block?.props?.attributes?.layoutType;
    if (sideArr.includes(layout) && block?.props?.name === 'cs-inner-cta') {
      blocksObj.side.push(block);
    } else {
      blocksObj.body.push(block);
    }
  }

  return blocksObj;
};

const CsMain = (props) => {
  const { attributes, children } = props;
  const { icon, sectionName } = attributes;

  const sectionIcon = getIcon(icon);

  const postBlocks = getPostBlocksFiltered(children, ['narrow']);

  return (
    <div
      className="cs-main-section customerSpotlight__container flex flex-col md:flex-row justify-between wrapper md:pt-64 md:pb-120 sm:pb-96 pt-48 pb-64 border-t-1 border-tertiary-gray2 gap-x-24"
      id={slugify(sectionName)}
    >
      <div className="cs-main-sidebar relative md:max-w-300 mb-40 sm:48 md:mb-0 flex flex-col justify-between">
        <div className="h-100%">
          <div className="flex items-center flex md:sticky md:top-120">
            <div className="imagex w-40 h-40 md:w-48 md:h-48 mr-16">
              <Image
                attributes={{
                  className: '',
                  mediaURL: sectionIcon,
                  nonResponsive: true,
                }}
              />
            </div>

            <h3 className="text-plum-800 mb-0">{sectionName}</h3>
          </div>
        </div>
        {postBlocks.side ? (
          <div className="w-100% hidden md:block md:mt-48 relative">
            {postBlocks.side}
          </div>
        )

          : null}
      </div>
      <div className="cs-main-content customerSpotlight__content flex-1 max-w-[735px] w-100%">
        {postBlocks.body}
      </div>
    </div>
  );
};

export default CsMain;
