import React, { useEffect } from 'react';

import classnames from 'classnames';
import { ErrorMessage, Field } from 'formik';

const TheField = (data) => {
  const {
    attributes,
    errors,
    formRef,
    handleBlur,
    handleChange,
    isActive,
    setValues,
    touched,
    values,
  } = data;
  let {
    className,
    dependency,
    innerClassName,
    isDependentField,
    isRequired,
    label,
    maxCharacters,
    name,
    placeholder,
    preText,
    rows,
    showError,
    type,
  } = attributes;

  const revealDependentField = () => {
    if (dependency) {
      const operators = {
        '<': (a, b) => a < b,
        '<=': (a, b) => a <= b,
        '=': (a, b) => a === b,
        '>': (a, b) => a > b,
        '>=': (a, b) => a >= b,
      };
      let [
        depName,
        operator,
        depValue,
      ] = dependency.split(',');

      let fieldValue = values[name];
      fieldValue = Number.parseInt(fieldValue) || fieldValue;
      depValue = Number.parseInt(depValue) || depValue;

      const isRevealed =
        fieldValue && operators[operator](fieldValue, depValue);

      const method = isRevealed ? 'add' : 'remove';
      const hiddenInput = formRef?.current?.querySelector(`[name=${depName}]`);

      if (hiddenInput) {
        const hiddenField = hiddenInput.closest('.-is-dependent-field');

        if (hiddenField) {
          hiddenField.classList[method]('-is-revealed');
        }
      }
    }
  };

  const onChange = (e) => {
    let val = e.target.value;
    val =
      type === 'number' && name === 'user_employees' ? val.slice(0, 4) : val;

    if (name) {
      setValues(Object.assign(values, { [name]: val }));
      if (handleChange) {
        handleChange(val);
      }
    }
  };

  let onBlur = (e) => {
    const val = e.target.value;

    if (name && handleBlur) {
      handleBlur(val);
    }
  };

  onBlur = dependency ? revealDependentField : onBlur;

  useEffect(() => {
    revealDependentField();
  }, []);

  const Wrapper = label ? 'label' : 'div';

  const as = type === 'textarea' ? 'textarea' : undefined;
  type = type === 'textarea' ? null : type;

  return (
    <Wrapper
      className={classnames({
        '-is-dependent-field': isDependentField,
        [className]: className,
        'field-2': !!label,
      })}
    >
      {preText && <p className="field_pretext">{preText}</p>}
      {label && <span className="field_label">{label}</span>}
      <Field
        as={as}
        className={`${label ? '' : 'field '}${innerClassName}${
          errors[name] && touched[name] ? ' -error' : ''
        }`}
        data-dependency={dependency}
        maxLength={maxCharacters}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        placeholder={placeholder}
        required={isRequired}
        rows={rows}
        tabIndex={!isActive || isDependentField ? -1 : undefined}
        type={type || 'text'}
      />

      {maxCharacters && values[name] !== undefined ? (
        <p className="is-style-p2 opacity-70">
          {values[name].length} / {maxCharacters}
        </p>
      )

        :
        ''
      }

      {showError && (
        <ErrorMessage
          className="form_errorMessage"
          component="div"
          name={name}
        />
      )}
    </Wrapper>
  );
};

export default TheField;
