import React, {
  useEffect, useRef, useState
} from 'react';

import amplitude from 'amplitude-js';
import Cookies from 'universal-cookie';

import { getXDayFromNow } from '../../helpers/date-helpers';
import { getIncrementValue, recursiveMap } from '../helpers';

const Toggler = ({ attributes, children }) => {
  const {
    autoPlayTime, className, hideInitial, initialIndex, onMount,
  } =
    attributes || {};

  const [activeIndex, setActiveIndex] = useState(initialIndex || (hideInitial ? null : 0));
  const [isVisible, setIsVisible] = useState(false);

  let triggerIndex = null;
  let targetIndex = null;
  const numOfItems = useRef();
  const videos = useRef([]);
  const animations = useRef([]);
  const timer = useRef();
  const togglerRef = useRef(null);

  const handleIntersect = (event) => {
    if (event[0].isIntersecting) {
      if (activeIndex === null) {
        setActiveIndex(0);
      }
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: [0, 1],
    };

    const observer = new IntersectionObserver(handleIntersect, options);
    togglerRef.current === null ? null : observer.observe(togglerRef.current);

    if (onMount) {
      var onMountFnc = new Function('values', onMount);
      const cookies = new Cookies();

      onMountFnc({
        amplitude,
        cookies,
        getXDayFromNow,
        setActiveIndex,
      });
    }

    return () => {
      return togglerRef.current === null
        ? null
        : observer.unobserve(togglerRef.current);
    };
  }, []);

  useEffect(() => {
    if (autoPlayTime) {
      clearTimeout(timer.current);

      timer.current = setTimeout(() => {
        if (isVisible) {
          const newActiveIndex = getIncrementValue({
            activeIndex,
            increment: 1,
            numOfItems: numOfItems.current,
          });
          setActiveIndex(newActiveIndex);
        }
      }, autoPlayTime);
    }
  }, [activeIndex, isVisible]);

  if (numOfItems.current === undefined) {
    numOfItems.current = 0;

    recursiveMap(children, (child) => {
      // @TODO: May no longer be necesarry. Stray targets aren't used to pass
      // data-active... since new selector allows descendents to be targeted
      if (child.props.attributes?.alias === 'Targets') {
        numOfItems.current = 0;
      }

      switch (child.props.name) {
        case 'target': {
          if (child.props.attributes.alias) {
            break;
          }
          numOfItems.current++;
          break;
        }
        case 'video': {
          if (child.props.attributes) {
            videos.current.push(child.props.attributes.duration);
          }
          break;
        }
        case 'animation': {
          if (child.props.attributes) {
            animations.current.push(child.props.attributes.duration);
          }
        }
      }

      if (React.isValidElement(child)) {
        return React.cloneElement(child, {});
      }

      return child;
    });
  }

  const childrenWithProps = recursiveMap(children, (child, i) => {
    let props;

    switch (child.props.name) {
      case 'trigger': {
        triggerIndex = i;

        props = {
          ...child.props,
          activeIndex,
          index: i,
          numOfItems: numOfItems.current,
          setActiveIndex,
        };
        break;
      }
      case 'target': {
        targetIndex = i;

        props = {
          ...child.props,
          activeIndex,
          index: i,
          numOfItems: numOfItems.current,
        };
        break;
      }
      case 'video': {
        props = {
          ...child.props,
          isActive: targetIndex === activeIndex,
          isToggled: true,
          onEnded: () => {
            const newActiveIndex =
              activeIndex + 1 === numOfItems.current ? 0 : activeIndex + 1;
            setActiveIndex(newActiveIndex);
          },
        };
        break;
      }
      case 'animation': {
        const options = child.props.attributes.opts;

        props = {
          ...child.props,
          activeIndex,
          attributes: {
            ...child.props.attributes,
            opts: {
              ...options,
              loop: options && options.loop === false ? false : true,
            },
          },
          isActive: targetIndex === activeIndex,

          // @TODO address

          isToggled: true,
          onComplete: (activeIndex) => {
            const newActiveIndex =
              activeIndex + 1 === numOfItems.current ? 0 : activeIndex + 1;
            options && options.autoRotate === true
              ? setActiveIndex(newActiveIndex)
              : '';
          },
        };
        break;
      }
      case 'html': {
        if (/timer/.test(child.props.attributes.className)) {
          props = {
            ...child.props,
            attributes: {
              ...child.props.attributes,
              originalStyles: { animationDuration: `${videos.current[triggerIndex]}s` },
            },
          };
        }
        break;
      }
      case 'form-2': {
        targetIndex = i;

        props = {
          ...child.props,
          attributes: {
            ...child.props.attributes,
            customOnSuccess: () => setActiveIndex(activeIndex + 1),
          },
        };
        break;
      }
      case 'select': {
        targetIndex = i;

        props = {
          ...child.props,
          attributes: {
            ...child.props.attributes,
            onSelectChange: (e) =>
              new Function('values', child.props.attributes.onSelectChange)({
                activeIndex,
                e,
                setActiveIndex,
              }),
          },
        };
        break;
      }
    }

    if (React.isValidElement(child)) {
      return React.cloneElement(child, props);
    }

    return child;
  });

  return (
    <div
      ref={togglerRef}
      className={`${className}`}
      data-toggler
    >
      {childrenWithProps}
    </div>
  );
};

export default Toggler;
