import React from 'react';

const GlossaryLeadInText = ({ attributes }) => {
  const { copy } = attributes;

  return (
    <div className="mb-32">
      <div className="grid sm:max-w-725 w-100%">
        <div className="row">
          <div className="column flex flex-col items-start w-100% pb-32">
            <p dangerouslySetInnerHTML={{ __html: copy }} className="is-style-lead-in" />
          </div>
          <div className="h-2 bg-[#DDDBD6] w-136" />
        </div>
      </div>
    </div>
  );
};

export default GlossaryLeadInText;
